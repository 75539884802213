<template>
   <div class="py-8 flex items-center justify-between">
      <div>
         <div
            class="flex border border-gray-25 overflow-hidden rounded-md items-center"
         >
            <button
               type="button"
               :class="
                  selectedView === 'monthly' ?
                     'bg-primary-500 text-white text-sm font-medium'
                  :  ''
               "
               @click="changeView('monthly')"
               class="p-2"
            >
               Month
            </button>
            <button
               type="button"
               class="p-2"
               :class="
                  selectedView === 'week' ?
                     'bg-primary-500 text-white text-sm font-medium'
                  :  ''
               "
               @click="changeView('week')"
            >
               Week
            </button>
         </div>
      </div>
      <div class="flex gap-2 lg:gap-6 items-center">
         <div @click="onPrevClick">
            <ChevronLeftIcon
               class="h-6 w-6 cursor-pointer text-primary-500"
            ></ChevronLeftIcon>
         </div>
         <div class="font-medium text-lg">{{ currentDateTitle }}</div>
         <div @click="onNextClick">
            <ChevronRightIcon
               class="h-6 w-6 cursor-pointer text-primary-500"
            ></ChevronRightIcon>
         </div>
      </div>
      <div>
         <div class="flex gap-2 justify-around">
            <div
               class="flex rounded text-xs bg-orange-50 p-0 pt-0 h-10 px-3 gap-2 items-center"
            >
               <span class="bg-orange-500 rounded-full h-3 w-3"></span>
               <span class="text-xs sm:text-sm">Today</span>
            </div>
            <div class="flex rounded bg-primary-50 h-10 p-2 gap-2 items-center">
               <span class="bg-primary-500 rounded-full h-3 w-3"></span>
               <span class="text-xs sm:text-sm">Booked</span>
            </div>
            <div class="flex rounded bg-green-50 h-10 p-2 gap-2 items-center">
               <span class="bg-green-500 rounded-full h-3 w-3"></span>
               <span class="text-xs sm:text-sm">Off Time</span>
            </div>
         </div>
      </div>
   </div>

   <FullCalendar @next="showInfo" ref="fullCalendar" :options="calendarOptions">
      <template v-slot:eventContent="arg">
         <div
            :class="`overflow-x-scroll md:overflow-x-auto bg-${arg?.event?.extendedProps?.color}-50 border-l-${arg?.event?.extendedProps?.color}-500 border-l-4 text-${arg?.event?.extendedProps?.color}-500 scrollbar-small`"
            class="cursor-pointer p-1 py-3"
            @click="showInfo(arg)"
         >
            <div class="flex ml-1 mb-2 items-center gap-1">
               <div class="col-span-1">
                  <ClockIcon
                     :class="`h-4 w-4 pt-0 text-${arg?.event?.extendedProps?.color}-500`"
                  ></ClockIcon>
               </div>
               <div class="col-span-11 text-xs flex gap-0">
                  <div>
                     {{
                        new Date(
                           `${arg?.event?.extendedProps?.dateData}t${arg?.event?.extendedProps?.startData}`
                        ).toLocaleTimeString('en', {
                           hour: '2-digit',
                           minute: '2-digit',
                        })
                     }}
                  </div>
                  <div>-</div>
                  <div>
                     {{
                        new Date(
                           `${arg?.event?.extendedProps?.dateData}t${arg?.event?.extendedProps?.endData}`
                        ).toLocaleTimeString('en', {
                           hour: '2-digit',
                           minute: '2-digit',
                        })
                     }}
                  </div>
               </div>
            </div>
            <div class="text-xs ml-6 font-medium">
               {{ arg?.event?.title }}
            </div>
         </div>
      </template>
   </FullCalendar>
</template>

<script>
   import dayGridPlugin from '@fullcalendar/daygrid';
   import interactionPlugin from '@fullcalendar/interaction';
   import { defineAsyncComponent } from 'vue';

   // TODO Change to composible API
   const FullCalendar = defineAsyncComponent(
      () => import('@fullcalendar/vue3')
   );

   import { useTherapistApi } from '@/composables/therapistApi';
   import {
      ArrowLeftCircleIcon,
      ArrowRightCircleIcon,
   } from '@heroicons/vue/20/solid';
   import {
      ChevronLeftIcon,
      ChevronRightIcon,
      ClockIcon,
   } from '@heroicons/vue/24/outline';
   import apiRoutes from '~/const/apiRoutes';

   export default {
      props: {
         initialView: {
            type: String,
            default: 'dayGridMonth',
         },
      },
      components: {
         ClockIcon,
         ChevronLeftIcon,
         ChevronRightIcon,
         ArrowLeftCircleIcon,
         ArrowRightCircleIcon,
         FullCalendar, // make the <FullCalendar> tag available
      },

      data() {
         return {
            loading: true,
            currentDateTitle: this.$dayjs(new Date()).format('MMM, YYYY'),
            selectedView: 'monthly',
            activeRange: {
               startDate: this.$dayjs(new Date())
                  .startOf('month')
                  .format('YYYY-MM-DD'),
               endDate: this.$dayjs(new Date())
                  .endOf('month')
                  .format('YYYY-MM-DD'),
            },
            calendarOptions: {
               eventLimit: true, //

               headerToolbar: false,
               views: {
                  dayGridDay: {
                     dayMaxEventRows: 10,
                  },
                  dayGridMonth: {
                     dayMaxEventRows: 3,
                     moreLinkClick: this.showMore,
                     // adjust to 6 only for timeGridWeek/timeGridDay
                  },
               },

               plugins: [dayGridPlugin, interactionPlugin],
               timeGridFourDay: {
                  type: 'timeGrid',
                  duration: { days: 4 },
                  buttonText: '4 day',
               },
               moreLinkClick: this.showMore,
               handleNextClick: this.showMore,
               calendarEvents: [],

               initialView: 'dayGridMonth',
               dateClick: this.handleDateClick,

               timeZone: 'UTC',
               textColor: 'black',
               events: [],
               fetchedData: false,
            },
         };
      },

      async mounted() {
         await this.getCalendarEvents();
      },
      methods: {
         showMore(event) {
            let calendar = this.$refs['fullCalendar'].calendar;
            calendar.changeView('dayGridWeek', event.date);
         },
         onPrevClick() {
            let calendar = this.$refs['fullCalendar'].calendar;

            calendar.prev();
            let start = calendar.view.activeStart;
            let end = calendar.view.activeEnd;
            this.currentDateTitle = this.$dayjs(
               calendar.view.currentStart
            ).format('MMM, YYYY');
            this.activeRange = {
               startDate: this.$dayjs(start).format('YYYY-MM-DD'),
               endDate: this.$dayjs(end).format('YYYY-MM-DD'),
            };
            this.getCalendarEvents();
         },
         changeView(type) {
            if (type === 'week') {
               this.selectedView = 'week';
               let calendar = this.$refs['fullCalendar'].calendar;
               calendar.changeView('dayGridWeek');

               let start = calendar.view.activeStart;
               let end = calendar.view.activeEnd;
               this.activeRange = {
                  startDate: this.$dayjs(start).format('YYYY-MM-DD'),
                  endDate: this.$dayjs(end).format('YYYY-MM-DD'),
               };
               this.currentDateTitle = this.$dayjs(
                  calendar.view.currentStart
               ).format('MMM, YYYY');
               this.getCalendarEvents();
            }
            if (type === 'monthly') {
               this.selectedView = 'monthly';
               let calendar = this.$refs['fullCalendar'].calendar;
               calendar.changeView('dayGridMonth');

               let start = calendar.view.activeStart;
               let end = calendar.view.activeEnd;
               this.activeRange = {
                  startDate: this.$dayjs(start).format('YYYY-MM-DD'),
                  endDate: this.$dayjs(end).format('YYYY-MM-DD'),
               };
               this.currentDateTitle = this.$dayjs(
                  calendar.view.currentStart
               ).format('MMM, YYYY');
               this.getCalendarEvents();
            }
         },
         onNextClick() {
            let calendar = this.$refs['fullCalendar'].calendar;

            calendar.next();
            let start = calendar.view.activeStart;
            let end = calendar.view.activeEnd;
            this.activeRange = {
               startDate: this.$dayjs(start).format('YYYY-MM-DD'),
               endDate: this.$dayjs(end).format('YYYY-MM-DD'),
            };
            this.currentDateTitle = this.$dayjs(
               calendar.view.currentStart
            ).format('MMM, YYYY');
            this.getCalendarEvents();
         },
         async getCalendarEvents() {
            this.loading = true;
            let { data, pending, error } = await useTherapistApi(
               apiRoutes.therapist.schedule.calendar,
               {
                  method: 'GET',
                  query: {
                     start_date:
                        this.activeRange?.startDate ||
                        this.$dayjs().startOf('month').format('YYYY-MM-DD'),
                     end_date:
                        this.activeRange?.endDate ||
                        this.$dayjs(new Date())
                           .endOf('month')
                           .format('YYYY-MM-DD'),
                     view: 'month',
                  },
               }
            );
            if (data?.value) {
               this.calendarEvents = data.value;

               this.calendarOptions.events = this.modifyEventValue();

               this.fetchedData = true;
               this.loading = false;
            }
            if (error?.value) {
               this.loading = pending.value;
            }
         },

         modifyEventValue() {
            let modifiedDate = [];
            this.calendarEvents.map((event) => {
               let data = {};
               if (event?.events?.length) {
                  event.events.map((meta) => {
                     data = {
                        date: event?.date,
                        day_of_week: event?.day_of_week,
                        title: meta?.event_name,
                        startData: meta?.time?.start_time,
                        dateData: event?.date,
                        endData: meta?.time?.end_time,
                        id: meta?.id,
                        allDay: false,
                        extendedProps: {
                           color:
                              meta?.event_name === 'Time off' ?
                                 'secondary'
                              :  'primary',
                        },
                     };
                     modifiedDate.push(data);
                  });
               }
            });
            return modifiedDate;
         },
         handleDateClick() {},
         showInfo(detail) {
            this.$emit('event-clicked', detail?.event);
         },
         getTime(date) {
            // let time = date.split('T')[1]?.substring(0, 10);
            return date;
         },
      },
   };
</script>
