<template>
   <div class="rounded-lg shadow-md p-4">
      <div class="flex justify-between items-center mb-4">
         <h2 class="text-lg font-bold">{{ currentFilterDate }}</h2>
      </div>
      <div class="grid grid-cols-12">
         <div class="col-span-12">
            <div
               ref="containerNav"
               class="bg-white top-0 py-4 px-4 rounded-3xl mb-4 z-10 grid flex-none grid-cols-7 text-gray-1000 md:hidden"
            >
               <div class="overflow-x-scroll w-full col-span-7 flex gap-3">
                  <button
                     v-for="(day, dindex) in weekDays"
                     :key="dindex"
                     type="button"
                     @click="handleClick(day)"
                     :class="
                        currentDay === day.value ?
                           'bg-primary-500 rounded-3xl text-white'
                        :  ''
                     "
                     class="flex flex-col items-center px-2 pb-1.5 pt-3"
                     :id="day.value"
                  >
                     <span>{{ day.dayName }}</span>
                     <!-- Default: "text-gray-1000", Selected: "bg-gray-900 text-white", Today (Not Selected): "text-indigo-600", Today (Selected): "bg-indigo-600 text-white" -->
                     <span
                        :class="
                           currentDay === day.value ?
                              'bg-primary-500 rounded-3xl text-white'
                           :  ''
                        "
                        class="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-1000"
                        >{{ day.date }}</span
                     >
                  </button>
               </div>
            </div>
         </div>
      </div>
      <div class="grid grid-cols-1 mx-2 py-4 bg-white px-2 gap-6">
         <div
            v-for="(hour, index) in hours"
            :key="index"
            class="flex gap-2 border-b border-gray-500 border-opacity-50 items-center py-2 min-h-28 relative"
         >
            <span class="text-sm">{{ hour.label }}</span>
            <div
               v-if="events[index]"
               class="absolute top-0 bottom-0 start-20 end-0 flex gap-3 overflow-x-auto scrollbar-small p-2"
            >
               <div
                  v-for="(event, eventIndex) in events[index]"
                  :key="eventIndex"
                  :id="event.startDate"
                  @click="$emit('event-clicked', event)"
                  :class="
                     event?.status ?
                        'bg-primary-50 border-primary-500'
                     :  'bg-secondary-50 border-secondary-500'
                  "
                  class="border-l-2 left-38 w-72 rounded-lg shadow-md p-2"
               >
                  <div
                     :class="`mt-2  font-medium flex gap-2 text-${event?.status ? 'primary' : 'secondary'}-500`"
                  >
                     <ClockIcon
                        :class="`h-6 w-6 text-${event?.status ? 'primary' : 'secondary'}-500`"
                     ></ClockIcon>
                     <div class="text-nowrap">
                        {{
                           new Date(
                              `${dayjs(currentFilterDate).format('YYYY-MM-DD')} ${event?.startDate}`
                           ).toLocaleTimeString('en', {
                              hour: '2-digit',
                              minute: '2-digit',
                           })
                        }}
                     </div>
                     <div>-</div>
                     <div class="text-nowrap">
                        {{
                           new Date(
                              `${dayjs(currentFilterDate).format('YYYY-MM-DD')} ${event?.endDate}`
                           ).toLocaleTimeString('en', {
                              hour: '2-digit',
                              minute: '2-digit',
                           })
                        }}
                     </div>
                  </div>
                  <div class="ml-8 font-medium">
                     {{ event.eventName }}
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
   import { ClockIcon } from '@heroicons/vue/24/outline';
   import dayjs from 'dayjs';
   import { useTherapistApi } from '~/composables/therapistApi';
   import apiRoutes from '~/const/apiRoutes.js';
   const { useTimeFormat } = useTimeComposable();

   const hours = ref([
      {
         label: '12 AM',
         value: 275,
         format: '00:00',
      },
      {
         label: '1 AM',
         value: 1,
         format: '01:00',
      },
      {
         label: '2 AM',
         value: 6,
         format: '02:00',
      },
      {
         label: '3 AM',
         value: 12,
         format: '03:00',
      },
      {
         label: '4 AM',
         value: 18,
         format: '04:00',
      },
      {
         label: '5 AM',
         value: 24,
         format: '05:00',
      },
      {
         label: '6 AM',
         value: 30,
         format: '06:00',
      },
      {
         label: '7 AM',
         value: 36,
         format: '07:00',
      },
      {
         label: '8 AM',
         value: 42,
         format: '08:00',
      },
      {
         label: '9 AM',
         value: 48,
         format: '09:00',
      },
      {
         label: '10 AM',
         value: 56,
         format: '10:00',
      },
      {
         label: '11 AM',
         value: 62,
         format: '11:00',
      },
      {
         label: '12 PM',
         value: 68,
         format: '12:00',
      },
      {
         label: '1 PM',
         value: 74,
         format: '13:00',
      },
      {
         label: '2 PM',
         value: 80,
         format: '14:00',
      },
      {
         label: '3 PM',
         value: 86,
         format: '15:00',
      },
      {
         label: '4 PM',
         value: 92,
         format: '16:00',
      },
      {
         label: '5 PM',
         value: 98,
         format: '17:00',
      },
      {
         label: '6 PM',
         value: 104,
         format: '18:00',
      },
      {
         label: '7 PM',
         value: 20,
         format: '19:00',
      },
      {
         label: '8 PM',
         value: 116,
         format: '20:00',
      },
      {
         label: '9 PM',
         value: 262,
         format: '21:00',
      },
      {
         label: '10 PM',
         value: 275,
         format: '22:00',
      },
      {
         label: '11 PM',
         value: 275,
         format: '23:00',
      },
   ]);
   const currentDay = ref(dayjs(new Date()).format('dddd, DD MMM YYYY'));
   const baseDay = ref(dayjs());
   const weekDays = computed(() => {
      let days = [];
      for (let i = -5; i < 15; i++) {
         const day = baseDay.value.add(i, 'day');
         days.push({
            dayName: day.format('ddd'),
            date: day.format('DD'),
            value: day.format('dddd, DD MMM YYYY'),
         });
      }
      return days;
   });
   const handleClick = (day) => {
      currentDay.value = day.value;
      const difference = Math.abs(dayjs(day.value).diff(baseDay.value, 'day'));
      if (difference > 3) {
         baseDay.value = dayjs(day.value);
      }
   };

   const events = ref([
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
      [],
   ]);
   const eventLoading = ref(false);
   const eventsList = ref([]);
   function scrollTo(id) {
      const element = document.getElementById(id);
      if (element) {
         element.scrollIntoView({ behavior: 'smooth' });
      }
   }
   const currentYearMonth = dayjs(new Date()).format('YYYY-MM');
   const currentFilterDate = ref(dayjs(new Date()).format('dddd, DD MMM YYYY'));
   const getCurrentDayEvents = async (day) => {
      if (eventLoading?.value) return;
      eventLoading.value = true;
      eventsList.value = [];
      events.value = [
         [],
         [],
         [],
         [],
         [],
         [],
         [],
         [],
         [],
         [],
         [],
         [],
         [],
         [],
         [],
         [],
         [],
         [],
         [],
         [],
         [],
         [],
         [],
         [],
      ];
      let { data, error } = await useTherapistApi(
         apiRoutes?.therapist?.schedule?.calendar,
         {
            method: 'GET',
            query: {
               view: 'day',
               start_date: day,
               end_date: day,
            },
         }
      );
      if (data?.value) {
         eventLoading.value = false;
         data?.value.map((event) => {
            if (event?.events?.length > 0) {
               event.events.map((newEvent) => {
                  hours.value.forEach((item, eindex) => {
                     if (
                        item.format.split(':')[0] ===
                        newEvent?.time?.start_time?.split(':')[0]
                     ) {
                        events.value[eindex].push({
                           eventName: newEvent?.event_name,
                           id: newEvent.id,
                           startDate: dayjs(
                              newEvent?.time?.start_time,
                              'hh:mm'
                           ).format('HH:mm'),
                           endDate: dayjs(
                              newEvent?.time?.end_time,
                              'hh:mm'
                           ).format('HH:mm'),
                           status: newEvent?.status,
                        });
                     }
                  });
               });
            }
         });
         const firstEventId = events.value.find(
            (hourEvents) => hourEvents.length > 0
         )?.[0].startDate;
         if (firstEventId) {
            scrollTo(firstEventId);
         }
      }
   };
   watch(currentDay, async () => {
      let date = dayjs(currentDay.value).format('YYYY-MM-DD');
      currentFilterDate.value = dayjs(currentDay.value).format(
         'dddd, DD MMM YYYY'
      );

      await getCurrentDayEvents(date);

      const firstEventId = events.value.find(
         (hourEvents) => hourEvents.length > 0
      )?.[0].startDate;
      if (firstEventId) {
         scrollTo(firstEventId);
      }
   });
   onMounted(async () => {
      let date = dayjs(currentDay.value).format('YYYY-MM-DD');
      await getCurrentDayEvents(date);
      const firstEventId = events.value.find(
         (hourEvents) => hourEvents.length > 0
      )?.[0].startDate;
      if (firstEventId) {
         scrollTo(firstEventId);
      }
   });
</script>

<style scoped>
   .grid-cols-1 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
   }
</style>
